<template>
  <b-tabs>
    <b-tab
      title="Profil"
      active
    >
      <b-form
        ref="form"
      >
        <b-row>

          <!-- first name -->
          <b-col md="6">
            <b-form-group
              label="İsim"
              label-for="vi-first-name"
            >
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="UserIcon" />
                </b-input-group-prepend>
                <b-form-input
                  id="vi-first-name"
                  v-model="form.name"
                  disabled
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <!-- email -->
          <b-col md="6">
            <b-form-group
              label="Email"
              label-for="vi-email"
            >
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="MailIcon" />
                </b-input-group-prepend>
                <b-form-input
                  id="vi-email"
                  v-model.trim="form.email"
                  required
                  disabled
                  type="email"
                />
              </b-input-group>
            </b-form-group>
          </b-col>

          <!-- mobile -->
          <b-col md="6">
            <b-form-group
              label="Telefon"
              label-for="vi-mobile"
            >
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SmartphoneIcon" />
                </b-input-group-prepend>
                <b-form-input
                  id="vi-mobile"
                  v-model="form.phone"
                  type="number"
                  disabled
                />
              </b-input-group>
            </b-form-group>
          </b-col>

          <!-- password -->
          <b-col
            md="6"
          >
            <b-form-group
              label="Password"
              label-for="vi-password"
            >
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="LockIcon" />
                </b-input-group-prepend>
                <b-form-input
                  id="vi-password"
                  v-model="form.password"
                  type="password"
                  disabled
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Kısa isim"
              label-for="vi-short_name"
            >
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="UserIcon" />
                </b-input-group-prepend>
                <b-form-input
                  id="vi-short_name"
                  v-model="form.short_name"
                  disabled
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Tip"
              label-for="vi-contact_type"
            >
              <b-form-select
                id="vi-contact_type"
                v-model="form.contact_type"
                disabled
                :options="contact_types"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="tax_office"
              label-for="vi-tax_office"
            >
              <b-form-input
                id="vi-tax_office"
                v-model="form.tax_office"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="TC No"
              label-for="vi-tax_number"
            >
              <b-form-input
                id="vi-tax_number"
                v-model="form.tax_number"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="İlçe"
              label-for="vi-district"
            >
              <b-form-input
                id="vi-district"
                v-model="form.district"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="İl"
              label-for="vi-city"
            >
              <b-form-input
                id="vi-city"
                v-model="form.city"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Ülke"
              label-for="vi-country"
            >
              <b-form-input
                id="vi-country"
                v-model="form.country"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Adress"
              label-for="vi-address"
            >
              <b-form-input
                id="vi-address"
                v-model="form.address"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="fax"
              label-for="vi-fax"
            >
              <b-form-input
                id="vi-fax"
                v-model="form.fax"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="İban"
              label-for="vi-iban"
            >
              <b-form-input
                id="vi-iban"
                v-model="form.iban"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="İzlenemez"
              label-for="vi-untrackable"
            >
              <b-form-checkbox
                id="vi-untrackable"
                v-model="form.untrackable"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Yurt Dışı"
              label-for="vi-is_abroad"
            >
              <b-form-checkbox
                id="vi-is_abroad"
                v-model="form.is_abroad"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Arşiv"
              label-for="vi-archived"
            >
              <b-form-checkbox
                id="vi-archived"
                v-model="form.archived"
                disabled
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-tab>
    <b-tab title="Şifre">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        type="submit"
        variant="danger"
        class="mr-1"
        @click="resetPassword"
      >
        Şifre Sıfırla
      </b-button>
    </b-tab>
    <b-tab title="Talep Oluştur">
      <b-form
        ref="requestForm"
        @submit.prevent="sendReq()"
      >
        <b-form-group
          label="Talebiniz"
          label-for="req"
        >
          <b-form-textarea
            id="req"
            v-model="userrequest"
          />
        </b-form-group>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="primary"
          type="submit"
        >
          Talep Oluştur
        </b-button>
      </b-form>
    </b-tab>
  </b-tabs>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BInputGroup, BInputGroupPrepend, BFormSelect, BTab, BTabs, BFormTextarea,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BFormTextarea,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BInputGroup,
    BInputGroupPrepend,
    BForm,
    BButton,
    BFormSelect,
    BTab,
    BTabs,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      userrequest: '',
      form: {
        email: null,
        password: null,
        name: null,
        short_name: null,
        contact_type: null,
        tax_office: null,
        tax_number: null,
        district: null,
        city: null,
        country: null,
        address: null,
        phone: null,
        fax: null,
        is_abroad: false,
        archived: false,
        iban: null,
        account_type: 'customer',
        untrackable: false,
      },
      contact_types: [
        {
          text: 'Kişi',
          value: 'person',
        },
        {
          text: 'Firma',
          value: 'company',
        },
      ],
    }
  },
  computed: {
    editControl() {
      return !!this.$route.query.editId
    },
  },
  created() {
    Object.assign(this.form, this.$store.state.auth.userInfo.parasut)
  },
  methods: {
    ...mapActions('customer', {
      addCustomer: 'addCustomer',
    }),
    resetPassword() {
      this.$http.post('user/forgot-password', {
        email: this.$store.state.auth.user.email,
        userrequest: this.userrequest,
      }).then(res => {
        if (res.data) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Başarılı',
              text: 'Mail adresinize şifre sıfırlama linki başarılı bir şekilde gönderildi.',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        }
      })
    },
    sendReq() {
      this.$http.post('customer/request', {
        email: this.$store.state.auth.user.email,
      }).then(res => {
        if (res.data) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Başarılı',
              text: 'Talebiniz başarılı bir şekilde iletildi en kısa sürede sönüş olacaktır.',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$refs.requestForm.reset()
        }
      })
    },
  },
}
</script>
